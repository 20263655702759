import React, { useMemo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash';
import { Card } from '@vartanainc/design-system';

import { WidgetContext } from '../../../context/WidgetContext';
import { OrderShowContext } from '../../../context/OrderContext';
import SendCheckoutLinkForm from '../SendCheckoutLinkForm';
import TrackRecipients from './TrackRecipients';
import usePermissions from '../../../utils/hooks/permissions';
import { editNotAllowedStates } from '../../../constants/common.constants';
import CommonWidgetV2 from '../../CommonWidgetV2/commonWidget';

// Order states
const CHECKOUT_DISABLED_VENDOR_STATES = [
  'pending',
  'canceled',
  'expired',
  'approval_required',
];

function TrackStatus({ orderData }) {
  const { refetchOrderData, selectedCustomer } = useContext(OrderShowContext);
  const [, hasPermission] = usePermissions();
  const [formFocusSwitch, setFormFocusSwitch] = useState(false);

  const trackRecipients = useMemo(() => {
    const emailHistory = get(orderData, 'emailHistory', []);
    if (emailHistory.length) {
      return (
        <div className="h-full">
          <Card
            variant="fullWidth"
            content={(
              <TrackRecipients
                emailHistory={emailHistory.map((email) => ({ id: uuidv4(), ...email }))}
              />
            )}
          >
          </Card>
        </div>
      );
    }
    return null;
  }, [orderData]);

  const widgetContext = useMemo(
    () => ({
      selectedOrder: orderData,
      screen: 'order-track',
      selectedCompany: get(orderData, 'company', {}),
      refetchOrderData,
      setFormFocus: () => setFormFocusSwitch((e) => !e),
    }),
    [orderData, refetchOrderData]
  );

  const checkoutFormDisabled =
    CHECKOUT_DISABLED_VENDOR_STATES.includes(orderData?.vendorState) ||
    editNotAllowedStates.includes(orderData?.state) ||
    !hasPermission('order', 'forward_application', selectedCustomer);

  return (
    <div className="flex gap-6 h-full w-full">
      <SendCheckoutLinkForm
        orderNumber={get(orderData, 'number', '')}
        disabled={checkoutFormDisabled}
        signer={
          get(orderData, 'company.creditCheck.pgRequired', false)
            ? get(orderData, 'company.primaryUser', {})
            : {}
        }
        formFocusSwitch={formFocusSwitch}
      />

      <div className="flex flex-col gap-y-4 vd-order-track-screen-container">
        <div className="vd-order-track-container border rounded-md shadow justify-center">
          <WidgetContext.Provider value={widgetContext}>
            <CommonWidgetV2 />
          </WidgetContext.Provider>
        </div>
        {trackRecipients}
      </div>
    </div>
  );
}

TrackStatus.propTypes = {
  orderData: PropTypes.shape({
    number: PropTypes.string,
    state: PropTypes.string,
    vendorState: PropTypes.string,
    canUploadFundingInvoice: PropTypes.bool,
  }),
};

TrackStatus.defaultProps = {
  orderData: {},
};

export default TrackStatus;
