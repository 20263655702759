import { ReactElement, useState, useEffect } from 'react';
import { Typography } from '@vartanainc/design-system';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { navigateToCorrectVersion } from "../../../utils/helpers"
import { ModifyTerms } from '../../../macro_components/ModifyTerms/ModifyTermsWrapper';
import AutoLoad from '../../../components/AutoLoad';

export function RequestHigherOfferLimitsV2(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const navigate = useNavigate();
  const { companyNumber: customerNumber } = useParams();
  const location = useLocation();

  useEffect(() => {
    // this redirects to the new page if user tries to access the old page
    navigateToCorrectVersion("v2", location, navigate)
  }, [location, navigate])

  return (
    <AutoLoad loading={loading} containerClassName="absolute top-1/2 left-1/2">
      <div className="flex flex-col h-full">
        <div className="px-16 border-b border-gray-200 bg-white flex items-center min-h-[6.375rem]">
          <Typography variant="heading24" color="color-black-100">
            {companyName}
          </Typography>
        </div>
        <ModifyTerms
          setLoading={setLoading}
          setCompanyName={setCompanyName}
          onSuccess={() => {
            navigate(`/dashboard/customers/${customerNumber}/summary`);
          }}
        />
      </div>
    </AutoLoad>
  );
}
