import { ReactElement, useState, useEffect } from 'react';
import { Typography } from '@vartanainc/design-system';
import { useNavigate, useLocation } from 'react-router-dom';
import { navigateToCorrectVersion } from "../../../../utils/helpers"


import { ModifyTerms } from '../../../../macro_components/ModifyTerms/ModifyTermsWrapper';
import AutoLoad from '../../../../components/AutoLoad';
import './RequestHigherLimitV2.scss';

import SubmissionComplete from './SubmissionComplete';

export default function RequestHigherLimitV2(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // this redirects to the new page if user tries to access the old page
    navigateToCorrectVersion("v2", location, navigate)
  }, [location, navigate])

  return (
    <AutoLoad loading={loading} containerClassName="absolute top-1/2 left-1/2">
      <div className="flex flex-col h-full pb-24 bg-vartana-steel-20 min-h-[calc(100vh-4rem)]">
        <div className="flex flex-col gap-2 px-8 pt-6 border-b border-gray-200 min-h-[6.375rem] align-middle">
          <Typography variant="heading24" color="color-black-100">
            {companyName}
          </Typography>
          <Typography variant="paragraph10" bold color="color-black-100">
            Modify payment options
          </Typography>
        </div>
        {formSubmitted ? (
          <SubmissionComplete />
        ) : (
          <ModifyTerms
            setLoading={setLoading}
            setCompanyName={setCompanyName}
            onSuccess={() => {
              setFormSubmitted(true);
            }}
          />
        )}
      </div>
    </AutoLoad>
  );
}
