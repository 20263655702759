import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { Card } from '@vartanainc/design-system';
import { GET_ORDER_BY } from '../../../../graphql/queries/order';

import AutoLoad from '../../../../components/AutoLoad';
import SendCheckoutLinkForm from '../../page_components/SendCheckoutLinkForm';
import TrackRecipients from './TrackRecipients';
import { WidgetContext } from '../../../../context/WidgetContext';
import CommonWidgetV2 from '../../../CommonWidgetV2/commonWidget';
import { orderNoEditStates } from '../../../../constants/common.constants';

function TrackStatus({ companyNumber, crmOpportunityId, orderNumber }) {
  const [formFocusSwitch, setFormFocusSwitch] = useState(false);
  const { data: orderData, loading: orderLoading } = useQuery(GET_ORDER_BY, {
    variables: {
      number: orderNumber,
      crmOpportunityId,
    },
  });

  const trackRecipients = useMemo(() => {
    if (get(orderData, 'orderBy.emailHistory', []).length) {
      return (
        <div className="h-full">
          <Card
            variant="fullWidth"
            content={(
              <TrackRecipients
                emailHistory={get(orderData, 'orderBy.emailHistory', [])}
              />
            )}
          >
          </Card>
        </div>
      );
    }
    return null;
  }, [orderData]);

  const widgetContext = useMemo(
    () => ({
      selectedOrder: get(orderData, 'orderBy', {}),
      screen: 'order-track',
      selectedCompany: get(orderData, 'orderBy.company', {}),
      isExpandedApp: true,
      setFormFocus: () => setFormFocusSwitch((e) => !e),
    }),
    [orderData]
  );

  const trackStatus = useMemo(() => {
    return orderData?.orderBy ? (
      <div className="vd-order-track-container border rounded-md shadow justify-center">
        <WidgetContext.Provider value={widgetContext}>
          <CommonWidgetV2 />
        </WidgetContext.Provider>
      </div>
    ) : null;
  }, [orderData, widgetContext]);

  return (
    <AutoLoad className="mt-6" loading={orderLoading}>
      <div className="flex gap-6 h-full">
        <SendCheckoutLinkForm
          companyNumber={companyNumber}
          crmOpportunityId={crmOpportunityId}
          orderNumber={orderNumber}
          disabled={orderNoEditStates.includes(get(orderData, 'orderBy.state'))}
          signer={
            get(orderData, 'orderBy.company.creditCheck.pgRequired', {})
              ? get(orderData, 'orderBy.company.primaryUser', {})
              : {}
          }
          formFocusSwitch={formFocusSwitch}
        />
        <div className="grid grid-flow-row auto-rows-max gap-y-4 vd-order-track-screen-container">
          {trackStatus}
          {trackRecipients}
        </div>
      </div>
    </AutoLoad>
  );
}

TrackStatus.propTypes = {
  companyNumber: PropTypes.string.isRequired,
  crmOpportunityId: PropTypes.string,
  orderNumber: PropTypes.string,
};

TrackStatus.defaultProps = {
  crmOpportunityId: '',
  orderNumber: '',
};

export default TrackStatus;
